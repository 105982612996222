export default {
  classType: 'components',

  initClass(app) {
    app.modal = {};

    if (app.modal.modalMain) {
      // Listen for modal event.
      app.adaptive.addEventListener('modal_open', (data) => {
        let modal = app.modal.modalMain;
        modal.fill(data);
        modal.open();
      });
    }
  },

  init(options) {
    this.elContent = this.el.querySelector('.modal-page-content');
    this.initAssets();

    this.mouseDownOverlayProxy = this.mouseDownOverlay.bind(this);
    this.mouseUpOverlayProxy = this.mouseUpOverlay.bind(this);

    this.closeProxy = () => {
      this.close();
    };

    if (options.main) {
      this.app.modal.modalMain = this;
    }
  },

  initAssets() {
    this.assets = { css: [], js: [] };
  },

  reset() {
    if (this.options.closeEvents) {
      this.options.closeEvents.forEach((eventName) =>
        this.app.adaptive.removeEventListener(eventName, this.closeProxy)
      );
    }
  },

  fill(options) {
    if (options.page.error) {
      this.app.prompt.toastError(options.page.error);
      return;
    }

    this.reset();

    // Copy persistent options.
    options.main = this.options.main;
    // Override all others.
    this.options = options;

    if (this.options.closeEvents) {
      this.options.closeEvents.forEach((eventName) =>
        this.app.adaptive.addEventListener(eventName, this.closeProxy)
      );
    }
  },

  open() {
    if (this.opened) {
      return;
    }

    this.opened = true;
    this.el.classList.remove('closed');
    this.el.classList.add('opened');
    this.el.addEventListener('mousedown', this.mouseDownOverlayProxy);
    this.el.addEventListener('mouseup', this.mouseUpOverlayProxy);

    this.el.querySelectorAll('.modal-header .close a').forEach((e) =>
      e.addEventListener('click', (e) => {
        e.preventDefault();
        this.close();
      })
    );
  },

  close(ignoreHistory, ignoreCloseCallback = false) {
    if (!this.opened || this.closing) {
      return;
    }

    this.closing = true;
    this.el.classList.remove('opened');
    this.el.classList.add('closed');
    this.el.removeEventListener('mousedown', this.mouseDownOverlayProxy);
    this.el.removeEventListener('mouseup', this.mouseUpOverlayProxy);
    // Sync with CSS animation.
    window.setTimeout(() => {
      this.el.classList.remove('closed');
      this.empty();
      if (this.options.main) {
        this.app.modal.close(ignoreHistory);
      }

      this.opened = false;
      this.closing = false;

      if (!ignoreCloseCallback) {
        this.options.closeCallback && this.options.closeCallback(this);
      }
    }, 400);
  },

  empty() {
    // Empty "page" content if exists.
    this.elContent && (this.elContent.innerHTML = '');

    for (let js of this.assets.js) {
      js.parentNode.removeChild(js);
    }

    for (let css of this.assets.css) {
      css.parentNode.removeChild(css);
    }

    this.initAssets();
  },

  mouseDownOverlay(e) {
    // Accept closing modal on clicking on the overlay,
    // only if the mousedown is started on the overlay itself.
    if (e.target === e.currentTarget) {
      this.mouseDownOverlayTarget = e.target;
      this.mouseDownOverlayTimestamp = Date.now();
    } else {
      this.mouseDownOverlayTarget = null;
      this.mouseDownOverlayTimestamp = null;
    }
  },

  mouseUpOverlay(e) {
    // Check that click has been on the same element.
    // Then prevent too long clicks.
    if (
      e.target === this.mouseDownOverlayTarget &&
      Date.now() - this.mouseDownOverlayTimestamp < 2000
    ) {
      this.close();
    }
  },

  loadingStart() {
    this.el.classList.remove('modal-loading-complete');
    this.el.classList.add('modal-loading-started');
  },

  loadingComplete() {
    this.el.classList.add('modal-loading-complete');

    // Cleanup.
    setTimeout(() => {
      this.el.classList.remove('modal-loading-complete');
      this.el.classList.remove('modal-loading-started');
    }, 1000);
  },
};
